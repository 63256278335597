<template>
  <div class="flex flex-row justify-end -mx-1">
    <!-- 無償ダイヤ -->
    <div v-if="props.type === 'gem'" class="w-1/2 px-1">
      <CommonPoint :value="wallet ? wallet.freeGem : 0" type="dia" label="無償" />
    </div>
    <div v-if="props.type === 'gem'" class="w-1/2 px-1">
      <!-- ゲソコイン -->
      <CommonPoint
        v-if="isAnimatePlatform()"
        :value="animateWallet ? animateWallet.coin : 0"
        :diamond-shop="props.diamondShop"
        type="gesocoin"
        label=""
        @on-add-point="onAddPoint"
      />
      <!-- 有償ダイヤ -->
      <CommonPoint
        v-else
        :value="wallet ? wallet.purchasedGem : 0"
        :diamond-shop="props.diamondShop"
        type="dia"
        label="有償"
        @on-add-point="onAddPoint"
      />
    </div>
    <!-- コイン -->
    <div v-if="props.type === 'gold'" class="w-1/2 px-1">
      <CommonPoint
        :value="wallet ? wallet.gold : 0"
        type="coin"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { isAnimatePlatform } from '~/libs/platform';

interface Props {
  type?: 'gem' | 'gold',
  diamondShop?: boolean,
}
const props = withDefaults(defineProps<Props>(), {
  type: 'gem',
  diamondShop: false,
});
interface Emits {
  (e: 'onAddPoint'): void;
}
const emit = defineEmits<Emits>();

const onAddPoint = () => {
  emit('onAddPoint');
};

const wallet = useWallet().wallet;
const animateWallet = useAnimateWallet().wallet;
</script>
